import React from "react"

import SEO from "../components/atoms/seo"
import VerticalLayout from "../layouts/vertical-layout"
import AriseSol from "../components/atoms/arisesol"

export default function AriseSolPage() {
  return (
    <VerticalLayout className='home' scene={() => <AriseSol />}>
      <SEO title='Arise Sol' image='willow.webp' description="A Mobile Game about Rebirth, Death, & Space." />
      <h1>
        <span>Arise</span> <span>Sol</span>
      </h1>
      <hr />
      <p className='subtitle'>
        A Mobile Game about Rebirth, Death, & Space.
      </p>
      <hr />
      <p>
        Arise Sol is a simulation game which grants you god-like powers to shape solar systems. You
        can move planets, moons, gas giants, stars, singularities and more, and transform the matter
        that makes them up, turning deserts into oceans, gas giants into terrestrial planets, or
        slowing the death of a star.
      </p>
      <p>
        Each solar system you watch over has a life cycle starting with its formation and ending
        when it's oldest star explodes. With each stellar death a nebula blossoms and new systems
        can form continuing your journey.
      </p>
      <p>
        Arise Sol challenges you to get the most out of a solar system with a variety of objectives,
        and to push you out of your comfort zone, each solar system has its own objectives. Some
        solar systems may want you to grow as much life as possible, while others look for you to
        smash as many planets as possible into each other.
      </p>
      <p>
        By accomplishing the challenges and quests you are faced with you are rewarded powerful
        items that empower you and unlock new abilities, such as the ability to split planets apart,
        spontaneously create life, or collapse stars into singularities, to name a few.
      </p>
      <p>
        We set out to create an idle game that could bring calm, and joy to our players, and in
        Arise Sol you can find that. Just as easily as you could take an active role changing the
        solar systems you have been entrusted with, you can also simply observe them, see if life
        forms all on its own, or myriad other possibilities.
      </p>
    </VerticalLayout>
  )
}
