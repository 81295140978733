import { Vector2 } from "three"

export const remap = (value, low1, high1, low2, high2) => {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
}

export const randomInt = (max) => Math.floor(Math.random() * max)

const v2Zero = new Vector2();

export const randomInsideUnitCircle = (radius = 1) => new Vector2().random().multiplyScalar(2).subScalar(1).normalize().lerp(v2Zero, Math.random()).multiplyScalar(radius)
export const randomUnitCircle = (radius = 1) => new Vector2().random().multiplyScalar(2).subScalar(1).normalize().multiplyScalar(radius)