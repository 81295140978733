import * as THREE from "three"
import THREECanvas from "./three-canvas"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass"
import { StarsShader } from "../../shaders/Stars"
import { randomInt } from "../../utils/math"

const UPVector = new THREE.Vector3(0, 1, 0)

const Colors = [[new THREE.Color(0xffffc7), new THREE.Color(0x485f78)]]

const StarProps = {
  dividend: 192,
  radiusOffset: 100,
  gap: 100,
  speed: 8
}

class Body {
  constructor(
    anchor = new THREE.Object3D(),
    speed = Math.random() * 5,
    orbit = new THREE.Vector3(0, 0, 1),
  ) {
    this.anchor = anchor
    this.speed = speed
    this.orbit = orbit
    this.bodies = []
  }

  add(body = new Body()) {
    this.bodies.push(body)
  }

  update(delta = 0.1) {
    this.anchor.children[0].rotateOnWorldAxis(
      UPVector,
      delta * THREE.MathUtils.DEG2RAD * this.speed * 10,
    )
    this.anchor.rotateZ(delta * THREE.MathUtils.DEG2RAD * this.speed)
    for (let i = this.bodies.length - 1; i >= 0; --i) {
      this.bodies[i].update(delta)
    }
  }
}

export default class AriseSol extends THREECanvas {
  start() {
    super.start()

    this.addStars()
    this.addSun()
    this.addPlanets()
  }

  initPostprocessing() {
    super.initPostprocessing()

    this.composer.addPass(new AfterimagePass(0.98))
    this.composer.addPass(new UnrealBloomPass(this.resolution, 3, 0, 0))
  }

  addStars() {
    const NUM_STARS = this.context.isMobile ? 1000 : 6000

    const positions = new Float32Array(NUM_STARS * 3)

    const vertex = new THREE.Vector3()

    const dividend = this.context.isMobile ? StarProps.dividend / 2 : StarProps.dividend;
    const radiusOffset = StarProps.radiusOffset;
    const gap = StarProps.gap;

    for (let i = NUM_STARS - 1; i >= 0; --i) {
      const radius = Math.floor(i / dividend) * gap + radiusOffset
      const angle = (360 / dividend) * (i % dividend) * THREE.MathUtils.DEG2RAD
      const pos = new THREE.Vector2(radius * Math.cos(angle), radius * Math.sin(angle))

      vertex.x = pos.x
      vertex.y = pos.y
      vertex.z = 0
      vertex.toArray(positions, i * 3)
    }

    const starGeometry = new THREE.BufferGeometry()
    starGeometry.setAttribute("position", new THREE.Float32BufferAttribute(positions, 3))

    const starsMat = new THREE.ShaderMaterial({
      uniforms: {
        size: { value: this.context.isMobile ? 10 : 1 },
        color: { value: new THREE.Color(0x333333) },
      },
      vertexShader: StarsShader.vertex,
      fragmentShader: StarsShader.fragment,
    })

    this.stars = new THREE.Points(starGeometry, starsMat)
    this.scene.add(this.stars)
  }

  addSun() {
    this.sphereGeometry = new THREE.SphereGeometry(1, 16, 8)

    this.colorCombo = Colors[randomInt(Colors.length)]

    const sunMaterial = new THREE.MeshStandardMaterial({
      color: this.colorCombo[0],
      wireframe: true,
      emissive: this.colorCombo[0],
      emissiveIntensity: 0.5
    })

    this.sun = new THREE.Mesh(this.sphereGeometry, sunMaterial)
    this.sun.scale.multiplyScalar(30)
    this.sun.translateZ(50)
    this.scene.add(this.sun)

    const solarLight = new THREE.PointLight(this.colorCombo[0], 0.05, 3000)
    const ambientLight = new THREE.AmbientLight(this.colorCombo[1], 0.4)

    this.scene.add(solarLight)
    this.scene.add(ambientLight)
  }

  addPlanets() { 

    const bodyMaterial = new THREE.MeshStandardMaterial({ wireframe: true, color: 0xffffff, emissive: 0xffffff, emissiveIntensity: 0})
    const NUM_PLANETS = Math.round(Math.random() * this.context.isMobile ? 5 : 10)

    const radius = (this.width > this.height ? this.width : this.height) / (this.context.isMobile ? 6 : 4)

    this.planets = []

    for (let i = NUM_PLANETS - 1; i >= 0; --i) {
      const anchor = new THREE.Object3D()
      const mesh = new THREE.Mesh(this.sphereGeometry, bodyMaterial)
      const planetRadius = Math.random() * 10 + 4

      mesh.translateX(Math.random() * radius * (Math.random() < 0.5 ? -1 : 1))
      mesh.translateY(Math.random() * radius * (Math.random() < 0.5 ? -1 : 1))
      mesh.scale.multiplyScalar(planetRadius)

      anchor.add(mesh)
      anchor.translateZ(125)

      const planet = new Body(anchor)

      for (let j = randomInt(3); j >= 0; --j) {
        let moonAnchor = new THREE.Object3D()
        let moon = new THREE.Mesh(this.sphereGeometry, bodyMaterial)
        moon.translateX(((Math.random() * planetRadius) / 4) * (Math.random() < 0.5 ? -1 : 1))
        moon.translateY(((Math.random() * planetRadius) / 4) * (Math.random() < 0.5 ? -1 : 1))
        moon.scale.multiplyScalar(Math.random() * 0.2)
        moonAnchor.add(moon)

        mesh.add(moonAnchor)
        planet.add(new Body(moonAnchor, Math.random() * 100))
      }

      this.scene.add(anchor)
      this.planets.push(planet)
    }
  }

  resize() {
    super.resize()

    const positions = this.stars.geometry.attributes.position.array

    const dividend = StarProps.dividend;
    const radiusOffset = StarProps.radiusOffset;
    const gap = StarProps.gap;

    for (let i = positions.length - 1; i >= 0; i -= 3) {
      const index = i / 3
      const radius = Math.floor(index / dividend) * gap + radiusOffset
      const angle = (360 / dividend) * (index % dividend) * THREE.MathUtils.DEG2RAD
      const pos = new THREE.Vector2(radius * Math.cos(angle), radius * Math.sin(angle))

      positions[i - 2] = pos.x
      positions[i - 1] = pos.y
      positions[i] = 0
    }
    this.stars.geometry.attributes.position.needsUpdate = true
  }

  getRadius() {
    return (this.width > this.height ? this.width : this.height) / 4
  }

  update(time, delta) {
    this.stars.rotateZ(delta * THREE.MathUtils.DEG2RAD * StarProps.speed)

    this.sun.rotateY(delta * THREE.MathUtils.DEG2RAD * 10)

    if(this.motion.lengthSq > 0)
      delta *= this.motion.length * 0.1

    for (let i = this.planets.length - 1; i >= 0; --i) {
      this.planets[i].update(delta)
    }

    const cPrime = new THREE.Vector3(
      ((this.mouse.x * 0.5 + this.orientation.x) / this.camera.aspect - this.camera.position.x) * 0.5,
      ((-this.mouse.y * 0.5 + this.orientation.y) / this.camera.aspect - this.camera.position.y) * 0.5,
      this.camera.position.z,
    )
    this.camera.position.copy(this.camera.position.lerp(cPrime, 0.01))
    this.camera.lookAt(this.scene.position)
  }
}
