export const StarsShader = {
  vertex: `
        uniform float size;

        void main() {
            vec4 mvPosition = viewMatrix * modelMatrix * vec4( position, 1.0 );
            gl_PointSize = size * ( 300.0 / -mvPosition.z );
            gl_Position = projectionMatrix * mvPosition;
        }
    `,
  fragment: `
        uniform vec3 color;

        void main() {
            gl_FragColor = vec4(color, 1);
        }
    `,
}
