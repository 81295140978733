import React from "react"

import * as THREE from "three"

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { MobileTestContext } from "../../utils/support"

export default class THREECanvas extends React.Component {
  /* Internal */
  componentDidMount() {
    window.addEventListener("resize", () => this.resize())
    window.addEventListener("mousemove", e => this.onPointerMove(e))
    window.DeviceMotionEvent && window.addEventListener("devicemotion", e => this.onDeviceMove(e))
    window.DeviceOrientationEvent &&
      window.addEventListener("deviceorientation", e => this.onDeviceRotate(e))

    this.start()
    this.animate()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.resize())
    window.removeEventListener("mousemove", e => this.onPointerMove(e))
    window.DeviceMotionEvent &&
      window.removeEventListener("devicemotion", e => this.onDeviceMove(e))
    window.DeviceOrientationEvent &&
      window.removeEventListener("deviceorientation", e => this.onDeviceRotate(e))

    window.cancelAnimationFrame(this.requestID)

    this.destroy()
  }

  /* Support */
  resize() {
    if (this.mount) {
      this.width = this.mount.clientWidth
      this.height = this.mount.clientHeight
    } else {
      this.width = window.innerWidth
      this.height = window.innerHeight
    }
    this.resolution.set(this.width, this.height)

    this.camera.aspect = this.width / this.height
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(this.width, this.height)
    this.composer.setSize(this.width, this.height)
  }

  onPointerMove(event) {
    if (event.isPrimary === false) return
    this.mouse = new THREE.Vector2(event.clientX - this.width / 2, event.clientY - this.height / 2)
  }

  onDeviceMove(event) {
    if (event.acceleration.x)
      this.motion = new THREE.Vector3(
        event.acceleration.x,
        event.acceleration.y,
        event.acceleration.z,
      )
  }

  onDeviceRotate(event) {
    if (event.beta) this.orientation = new THREE.Vector3(event.beta, event.gamma, event.alpha)
  }

  /* Lifecycle Hooks */
  start() {
    // Mouse
    this.mouse = new THREE.Vector2()

    // Device Motion
    this.motion = new THREE.Vector3(0, 0, 0)

    // Device Orientation
    this.orientation = new THREE.Vector3()

    // Resolution
    this.width = this.mount.clientWidth
    this.height = this.mount.clientHeight
    this.resolution = new THREE.Vector2(this.width, this.height)

    // Timer
    this.previousTime = performance.now()

    // Scene
    this.initScene()

    // Camera
    this.initCamera()

    // Renderer
    this.initRenderer()

    // Post Processing
    this.initPostprocessing()

    // Mounting Renderer
    this.mount.appendChild(this.renderer.domElement)
  }

  update(time, delta) {}

  destroy() {}

  /* Initialization */
  initScene() {
    this.scene = new THREE.Scene()
    this.scene.fog = new THREE.Fog(0x000000, 100, 500)
  }

  initCamera() {
    this.camera = new THREE.PerspectiveCamera(75, this.width / this.height, 1, 3000)
    this.camera.position.z = 400
  }

  initRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      antialias: !this.context.isMobile,
      precision: this.context.isMobile ? "lowp" : "mediump",
    })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(this.width, this.height)
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping
    this.renderer.toneMappingExposure = 1
    this.renderer.outputEncoding = THREE.sRGBEncoding
  }

  initPostprocessing() {
    // Render Pass
    const renderPass = new RenderPass(this.scene, this.camera)

    // Effect Composer
    this.composer = new EffectComposer(this.renderer)
    this.composer.addPass(renderPass)
  }

  /* Animation Loop */
  animate() {
    if (!this) return

    const time = performance.now()

    let delta = Math.min((time - this.previousTime) / 1000, 1)
    this.previousTime = time

    this.composer.render(delta)
    this.update(time, delta)

    this.requestID = window.requestAnimationFrame(() => this.animate())
  }

  /* Attaché */
  render() {
    return (
      <div
        style={{ width: "100vw", height: "100vh", position: "fixed" }}
        ref={ref => (this.mount = ref)}
      />
    )
  }
}

THREECanvas.contextType = MobileTestContext
